import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircle";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  checkNewSerialNumber,
  getToken,
  getUser,
  requestErrorHandler,
  ticketStates,
  USER_GROUPS,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import DetailTabs from "../../Views/DeviceDetails/DetailTabs";
import { showPopupSnackbar } from "../../redux/actions/snackbarActions";
import DeviceSearchAutocomplete from "../DeviceSearchAutocomplete";
import DialogAppBar from "../DialogAppBar";
import StockItemSearch from "../StockItems/StockItemSearch";
import ExpenseHeader from "./ExpenseHeader";
import ExpenseItem from "./ExpenseItem";
import MediaFiles from "./MediaFiles";

const server = EnvSettings.server;

const InputComponent = ({ inputRef, ...other }) => <div {...other} />;
const OutlinedDiv = ({ children, label }) => {
  return (
    <TextField
      variant="outlined"
      label={label}
      multiline
      fullWidth
      focused={false}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputComponent,
      }}
      inputProps={{ children: children }}
    />
  );
};

function ExpenseSegment({
  selectedTicket,
  expenseKey,
  ticketData,
  setTicketData,
}) {
  const [edit, setEdit] = useState();
  const [acKey, setACKey] = useState("dsfasdfds");
  const { t } = useTranslation();
  return (
    <>
      <Grid item container spacing={1} style={{ padding: 5 }}>
        <Grid item xs={12}>
          <InputLabel style={{ marginBottom: 3 }}>
            {t("ticketList." + expenseKey)}
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            label={t("name")}
            key={acKey + "3"}
            onChange={(e) => {
              setEdit(
                edit
                  ? { ...edit, name: e.target.value }
                  : { name: e.target.value }
              );
            }}
          ></TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            label={t("price")}
            type={"number"}
            key={acKey + "4"}
            onChange={(e) => {
              setEdit(
                edit
                  ? { ...edit, price: e.target.value }
                  : { price: e.target.value }
              );
            }}
          ></TextField>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              if (edit) {
                var exp = {
                  ...(selectedTicket ? selectedTicket.expenses : {}),
                };
                if (ticketData && ticketData.expenses) {
                  exp = { ...ticketData.expenses };
                }
                var arr = [];
                if (exp[expenseKey]) {
                  arr = [...exp[expenseKey]];
                  arr.push(edit);
                } else {
                  arr.push(edit);
                }
                exp[expenseKey] = [...arr];
                setTicketData({
                  ...ticketData,
                  expenses: { ...exp },
                });
                setACKey(new Date().toISOString());
              }
            }}
          >
            <AddIcon></AddIcon>
          </IconButton>
        </Grid>
      </Grid>
      <Grid item style={{ padding: 5 }}>
        {(ticketData &&
          ticketData.expenses &&
          ticketData.expenses[expenseKey] &&
          ticketData.expenses[expenseKey].length) ||
        (selectedTicket &&
          selectedTicket.expenses &&
          selectedTicket.expenses[expenseKey] &&
          selectedTicket.expenses[expenseKey].length) ? (
          <ExpenseHeader />
        ) : null}
        {[
          ...(ticketData &&
          ticketData.expenses &&
          ticketData.expenses[expenseKey]
            ? ticketData.expenses[expenseKey]
            : selectedTicket &&
              selectedTicket.expenses &&
              selectedTicket.expenses[expenseKey]
            ? selectedTicket.expenses[expenseKey]
            : []),
        ].map((m, i) => (
          <ExpenseItem
            obj={m}
            key={i}
            onClick={() => {
              var exp = {
                ...(selectedTicket ? selectedTicket.expenses : {}),
              };
              if (ticketData && ticketData.expenses) {
                exp = { ...ticketData.expenses };
              }
              var arr = [];
              arr = [...exp[expenseKey]];
              arr = arr.filter((a) => JSON.stringify(a) !== JSON.stringify(m));
              exp[expenseKey] = [...arr];
              setTicketData({ ...ticketData, expenses: { ...exp } });
            }}
          />
        ))}
      </Grid>
    </>
  );
}

const expenseKeys = ["shippingCosts", "outsourcedServices", "travelCosts"];

function Expenses({ ticketData, setTicketData, selectedTicket }) {
  const { t } = useTranslation();
  const [addNewItem, setAddNewItem] = useState(false);
  const [addPrice, setAddPrice] = useState(false);
  const [newItem, setNewItem] = useState();
  const [acKey, setACKey] = useState("sdfsdfsd");
  return (
    <Grid item xs={12} style={{ borderRadius: 5, borderWidth: 1 }}>
      <OutlinedDiv label={t("ticketList.expenses")}>
        <Grid item container style={{ padding: 5 }} spacing={1}>
          <Grid item xs={12}>
            <InputLabel style={{ marginBottom: 3 }}>
              {t("ticketList.spareParts")}
            </InputLabel>
          </Grid>

          {!addNewItem ? (
            <>
              <Grid item xs={addPrice ? 4 : 7}>
                <StockItemSearch
                  allowNewItems={true}
                  size={"small"}
                  onChange={(e, v) => {
                    if (v && v.sku) {
                      if (v.sku === "Add new item") {
                        setAddNewItem(true);
                        return;
                      }
                      if (!v.costPrice) {
                        setAddPrice(true);
                      }
                      setNewItem(
                        newItem
                          ? {
                              ...newItem,
                              name: v.sku,
                              price: v.costPrice,
                            }
                          : { name: v.sku, price: v.costPrice }
                      );
                    }
                  }}
                  // clearOnSelect={true}
                  includeTags={true}
                  placeholder={t("orders.startSearchingProducts")}
                  key={acKey + "98"}
                />
              </Grid>
              {addPrice ? (
                <Grid item xs={3}>
                  <TextField
                    size="small"
                    variant="outlined"
                    label={t("stockModule.costPrice")}
                    type={"number"}
                    key={acKey + "2"}
                    onChange={(e) => {
                      if (isNaN(e.target.value)) {
                        return;
                      }
                      setNewItem(
                        newItem
                          ? {
                              ...newItem,
                              price: e.target.value,
                            }
                          : { price: e.target.value }
                      );
                    }}
                  ></TextField>
                </Grid>
              ) : null}
            </>
          ) : (
            <>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  label={t("name")}
                  onChange={(e) => {
                    setNewItem(
                      newItem
                        ? { ...newItem, name: e.target.value }
                        : { name: e.target.value }
                    );
                  }}
                  key={acKey + "1"}
                ></TextField>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  label={t("stockModule.costPrice")}
                  type={"number"}
                  key={acKey + "2"}
                  onChange={(e) => {
                    setNewItem(
                      newItem
                        ? { ...newItem, price: e.target.value }
                        : { price: e.target.value }
                    );
                  }}
                ></TextField>
              </Grid>
            </>
          )}
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              type="number"
              size="small"
              fullWidth
              onChange={(e) => {
                setNewItem(
                  newItem
                    ? { ...newItem, count: e.target.value }
                    : { count: e.target.value }
                );
              }}
              inputProps={{ min: 0 }}
              label={t("count")}
              key={acKey + "99"}
            ></TextField>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => {
                if (newItem) {
                  if (!newItem.name || isNaN(newItem.price) || !newItem.count) {
                    return;
                  }
                  var exp = {
                    ...(selectedTicket ? selectedTicket.expenses : {}),
                  };
                  if (ticketData && ticketData.expenses) {
                    exp = { ...ticketData.expenses };
                  }
                  var arr = [];
                  if (exp.spareParts) {
                    arr = [...exp.spareParts];
                    arr.push(newItem);
                  } else {
                    arr.push(newItem);
                  }
                  exp.spareParts = [...arr];
                  setTicketData({
                    ...ticketData,
                    expenses: { ...exp },
                  });
                  setACKey(new Date().toISOString());
                  setAddNewItem(false);
                  setAddPrice(false);
                  setNewItem();
                }
              }}
            >
              <AddIcon></AddIcon>
            </IconButton>
          </Grid>
        </Grid>

        <Grid item style={{ padding: 5 }}>
          {(ticketData &&
            ticketData.expenses &&
            ticketData.expenses.spareParts &&
            ticketData.expenses.spareParts.length) ||
          (selectedTicket &&
            selectedTicket.expenses &&
            selectedTicket.expenses.spareParts &&
            selectedTicket.expenses.spareParts.length) ? (
            <ExpenseHeader />
          ) : null}
          {[
            ...(ticketData &&
            ticketData.expenses &&
            ticketData.expenses.spareParts
              ? ticketData.expenses.spareParts
              : selectedTicket &&
                selectedTicket.expenses &&
                selectedTicket.expenses.spareParts
              ? selectedTicket.expenses.spareParts
              : []),
          ].map((m, i) => (
            <ExpenseItem
              obj={m}
              key={i}
              onClick={() => {
                var exp = {
                  ...(selectedTicket ? selectedTicket.expenses : {}),
                };
                if (ticketData && ticketData.expenses) {
                  exp = { ...ticketData.expenses };
                }
                var arr = [];
                arr = [...exp.spareParts];
                arr = arr.filter(
                  (a) => JSON.stringify(a) !== JSON.stringify(m)
                );
                exp.spareParts = [...arr];
                setTicketData({
                  ...ticketData,
                  expenses: { ...exp },
                });
              }}
            />
          ))}
        </Grid>
        {expenseKeys.map((m) => (
          <ExpenseSegment
            key={m}
            selectedTicket={selectedTicket}
            ticketData={ticketData}
            setTicketData={setTicketData}
            expenseKey={m}
          />
        ))}

        <Grid item xs={12}>
          <Typography>
            {(ticketData && ticketData.expenses) ||
            (selectedTicket && selectedTicket.expenses)
              ? t("ticketList.totalExpenses") + ": "
              : null}

            {ticketData && ticketData.expenses
              ? Object.keys(ticketData.expenses).reduce(
                  (prev, k) =>
                    ticketData.expenses[k].reduce(
                      (prev, curr) =>
                        Number(curr.price) *
                          (curr.count ? Number(curr.count) : 1) +
                        prev,
                      0
                    ) + prev,
                  0
                ) + "€"
              : selectedTicket && selectedTicket.expenses
              ? Object.keys(selectedTicket.expenses).reduce(
                  (prev, k) =>
                    selectedTicket.expenses[k].reduce(
                      (prev, curr) =>
                        Number(curr.price) *
                          (curr.count ? Number(curr.count) : 1) +
                        prev,
                      0
                    ) + prev,
                  0
                ) + "€"
              : null}
          </Typography>
        </Grid>
      </OutlinedDiv>
    </Grid>
  );
}

function WhatsAppView({ selectedTicket: _selectedTicket }) {
  const ref = useRef();
  const [message, setMessage] = useState();
  const dispatch = useDispatch();
  const [acKey, setACKey] = useState("sdfasdf");
  const [selectedTicket, setSelectedTicket] = useState(_selectedTicket);

  const sendMessage = () => {
    if (!message) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        ticket_id: selectedTicket.id,
        message: message,
        recipient: selectedTicket.WhatsappMessages[0].WhatsappContact.number,
      }),
    };
    fetch(server + "/send_whatsapp_message", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          if (result.whatsappmessage) {
            var arr = [...selectedTicket.WhatsappMessages];
            arr.push({
              ...result.whatsappmessage,
              User: { fullName: getUser().name },
            });
            setSelectedTicket({
              ...selectedTicket,
              WhatsappMessages: [...arr],
            });
            setACKey(new Date().toISOString());
          }
        }
      });
  };

  useEffect(() => {
    if (ref && ref.current) {
      // Scroll to the latest message
      ref.current.scrollTo(0, ref.current.scrollHeight);
    }
  }, [ref, acKey]);

  return (
    <>
      <Grid
        item
        xs={12}
        container
        spacing={3}
        style={{
          maxHeight: 370,
          overflowY: "scroll",
          backgroundColor: "#e8ffe8",
          borderRadius: 5,
        }}
        key={acKey}
        ref={ref}
      >
        {selectedTicket && selectedTicket.WhatsappMessages
          ? selectedTicket.WhatsappMessages.sort(
              (a, b) => Number(a.timestamp) - Number(b.timestamp)
            ).map((m) => (
              <Grid
                item
                xs={12}
                style={{
                  border: "1px solid #000",
                  borderRadius: 10,
                  padding: 15,
                  margin: 5,
                  boxShadow: "rgba(0, 0, 0, 0.2) 2px 2px 0px 0px",
                  backgroundColor: "lightyellow",
                }}
              >
                <Typography style={{ fontSize: 12, whiteSpace: "pre-wrap" }}>
                  {new Date(Number(m.timestamp) * 1000)
                    .toISOString()
                    .substring(0, 19)
                    .replace("T", " ")}
                  {"\n"}
                  {m.User?.fullName ||
                    `${m.WhatsappContact?.name}\n+${m.WhatsappContact?.number}`}
                </Typography>
                <hr />
                {m.type !== "text" ? (
                  <Typography>{`{ ${m.type} }`}</Typography>
                ) : null}
                <Typography>{m.data.body || m.data.caption}</Typography>
              </Grid>
            ))
          : null}
      </Grid>
      <Grid
        item
        xs={12}
        container
        alignItems="center"
        style={{ marginTop: 20 }}
      >
        <Grid item xs={11}>
          <TextField
            variant="outlined"
            label="Message"
            fullWidth
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            key={acKey + "1"}
          ></TextField>
        </Grid>
        <Grid item xs={1}>
          <Button variant="contained" onClick={sendMessage}>
            Send
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default function TicketEntry({
  device_id,
  selectedTicket,
  setSelectedTicket,
  showTicketEntry: show,
  setShowTicketEntry: hide,
  setRefreshList,
  showSearch,
  showHeader,
  ...rest
}) {
  const [ticketData, setTicketData] = useState();
  const [confirmDelete, setConfirmDelete] = useState();
  const [acKey2, setACKey2] = useState("sdfsdfsd");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const submitTicket = () => {
    if (selectedTicket) {
      return;
    }
    if (!ticketData) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        deviceid: device_id,
        // Initial state is new unless ticketData overrides it
        ticket_data: { state: "new", ...ticketData },
      }),
    };
    fetch(server + "/add_ticket", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);

        if (!result.error) {
          dispatch(showPopupSnackbar(result));
          setTicketData();
          hide(false);
          if (setRefreshList) {
            setRefreshList(new Date().toISOString());
          }
        }
      });
  };

  const handleClose = () => {
    setSelectedTicket();
    setTicketData();
    setConfirmDelete();
    hide(false);
  };

  const editTicket = (deleteTicket) => {
    if (!selectedTicket) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        deviceid: device_id ?? selectedTicket.DeviceId,
        ticket_data: { ...selectedTicket, ...ticketData },
        deleteTicket: deleteTicket ?? undefined,
      }),
    };
    fetch(server + "/edit_ticket", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);

        if (!result.error) {
          dispatch(showPopupSnackbar(result));
          setTicketData();
          hide(false);
          if (setRefreshList) {
            setRefreshList(new Date().toISOString());
          }
        }
      });
  };
  return show ? (
    <Dialog open={!!show} fullScreen onClose={handleClose}>
      <DialogContent>
        <DialogAppBar
          onClose={handleClose}
          header={
            !selectedTicket && showSearch ? (
              t("newTicket")
            ) : selectedTicket?.Device && showHeader ? (
              <>
                {" "}
                {selectedTicket?.Device.serialNumber} -{" "}
                <Link
                  href={"/cpanel/devices/" + selectedTicket?.Device.id}
                  style={{ color: "white" }}
                >
                  {t("viewDevicePage")}
                </Link>
              </>
            ) : null
          }
        />
        <Grid
          style={{ marginTop: 60 }}
          container
          spacing={3}
          justifyContent="space-around"
        >
          {!selectedTicket && showSearch ? (
            <Grid item xs={12}>
              <DeviceSearchAutocomplete
                onChange={(e, v) => {
                  if (v && typeof v !== "string") {
                    if (v.inputValue) {
                      var x = checkNewSerialNumber(v.serialNumber);
                      if (x) {
                        alert(x);
                        setTicketData({
                          ...ticketData,
                          DeviceId: undefined,
                        });
                        setACKey2(Date.now());
                        return;
                      }
                      setTicketData({
                        ...ticketData,
                        newDevice: v.serialNumber,
                      });
                      return;
                    }

                    setTicketData({
                      ...ticketData,
                      DeviceId: v.id,
                    });
                    return;
                  }
                  setTicketData({ ...ticketData, device: undefined });
                }}
                allowNewDevices={true}
                // This key hack allows to reset the element after bad input
                key={acKey2}
              />
              {ticketData?.newDevice ? (
                <Typography>
                  {t("addingNewDevice")}: {ticketData.newDevice.toUpperCase()}
                </Typography>
              ) : null}
            </Grid>
          ) : null}
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              defaultValue={selectedTicket?.description}
              rows={4}
              label={t("ticketList.description")}
              onChange={(e) => {
                if (e.target.value) {
                  setTicketData({ ...ticketData, description: e.target.value });
                  return;
                }
                setTicketData({ ...ticketData, description: undefined });
              }}
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              defaultValue={selectedTicket?.action}
              rows={4}
              label={t("ticketList.action")}
              onChange={(e) => {
                if (e.target.value) {
                  setTicketData({ ...ticketData, action: e.target.value });
                  return;
                }
                setTicketData({ ...ticketData, action: undefined });
              }}
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              defaultValue={selectedTicket?.details}
              rows={4}
              label={t("ticketList.details")}
              onChange={(e) => {
                if (e.target.value) {
                  setTicketData({ ...ticketData, details: e.target.value });
                  return;
                }
                setTicketData({ ...ticketData, details: undefined });
              }}
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              select
              fullWidth
              defaultValue={selectedTicket?.state ?? "new"}
              label={t("ticketList.state")}
              onChange={(e, v) => {
                setTicketData({ ...ticketData, state: e.target.value });
              }}
            >
              {ticketStates.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {t("ticketList." + option.value)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <DetailTabs
              activeTabs={[
                {
                  permission: [USER_GROUPS.ADMIN, USER_GROUPS.SUPER_USER],
                  label: t("media"),
                  content: <MediaFiles ticket={selectedTicket} />,
                },
                {
                  permission: [USER_GROUPS.ADMIN, USER_GROUPS.SUPER_USER],
                  label: t("ticketList.expenses"),
                  content: (
                    <Expenses
                      ticketData={ticketData}
                      setTicketData={setTicketData}
                      selectedTicket={selectedTicket}
                    />
                  ),
                },
                // {
                //   permission: [USER_GROUPS.SUPER_USER],
                //   label: "WhatsApp",
                //   content: <WhatsAppView selectedTicket={selectedTicket} />,
                //   disabled: true,
                // },
              ]}
            />
          </Grid>
          <Grid container item xs={6} spacing={3}>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  if (selectedTicket) {
                    editTicket();
                    return;
                  }
                  submitTicket();
                }}
              >
                {t("submit")}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={handleClose}>
                {t("cancel")}
              </Button>
            </Grid>
            {selectedTicket && !confirmDelete ? (
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setConfirmDelete(true);
                  }}
                >
                  {t("delete")}
                </Button>
              </Grid>
            ) : null}
            {confirmDelete ? (
              <Grid item>
                <Typography>{t("areYouSure")}?</Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    editTicket(true);
                    setConfirmDelete(false);
                  }}
                >
                  {t("confirmDelete")}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setConfirmDelete(false);
                  }}
                >
                  {t("cancel")}
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  ) : null;
}
